@import url(//fonts.googleapis.com/css?family=Muli:wght@400;600;700;800;900&display=swap);
@import url(//fonts.googleapis.com/css?family=Muli);
@import url(//fonts.googleapis.com/css?family=Muli);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom {
    padding: 0 !important;
}

.MuiList-root {
  background-color: #121212;
}

ul.MuiList-root li span {
  color: #9e9e9e;
}

ul.dropdown-content.select-dropdown li span:hover {
  background-color: #9e9e9e !important;
}


.MuiMenuItem-root:hover {
    background-color: #4A4A4A !important;
}



.select-wrapper input.select-dropdown {
    color: aliceblue;
}

.MuiAutocomplete-option {
    background-color: #222222;
    color: #FAFAFA;
}

.MuiAutocomplete-option:hover {
    background-color: #4A4A4A !important;
}

.MuiAutocomplete-option:focus {
    background-color: #c00f0f !important;
}

.MuiAutocomplete-noOptions {
    background-color: #222222;
    color: #FAFAFA !important;
}

.MuiAutocomplete-listbox{
    background-color: #222222;
}
.imagen {
  border: solid 4px #16B1F3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.input-image {
  display: none !important;
  border-color: #424242 !important;
  border: none !important;
  background-color: transparent !important;
}

.title .MuiTypography-h5 {
    font-size: 20px;
    font-family: "Muli";
    font-weight: bold;
    line-height: 1.334;
    letter-spacing: 0em;
    color: #ffffff;
    text-align: center;
}

.titleCard .MuiTypography-h5 {
    font-size: 20px;
    font-family: "Muli";
    font-weight: bold;
    line-height: 1.334;
    letter-spacing: 0em;
    color: #ffffff;
    margin-left: 20px;
}

  
  /* Style the button and place it in the middle of the container/image */
  .containerImg .span {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    padding: 50px 50px;
    border: none; 
    border-radius: 80px;
    font-family: Muli;
    font-weight: bold;
    font-size: 30px;
  }

.colorBlanco{
  color: white;
}


.backgroundContent{
    background-color: black;
  }

  .backgroundContentText{
    color:white;
  }

  .positionSelect{
    margin: 8px;
    justify-content: center;
    align-content: center;
    position: relative;
    /* display: flex; */
    padding: 4px;
  }

.containerGrid {
    width: 100%;
    height: 100%;
  }

  /* Make the image responsive */
  .containerGrid img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  /* Style the button and place it in the middle of the container/image */
  .containerGrid .span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    padding: 50px 50px;
    border: none; 
    border-radius: 80px;
    font-weight: bold;
    font-size: 50px;
  }
  
.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.container {
  position: relative;
  width: 100%;
  /* max-height: 35vh; */
}

/* Imagen con aspecto 4:3 */
.container-img {
  width: 100%;
  background-repeat: no-repeat !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  padding-top: 65% !important;
  border-radius: 10px !important;
  background-size: contain !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}


/* Make the image responsive */

.container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Style the button and place it in the middle of the container/image */

.container .btn {
  position: absolute;
  top: 80%;
  left: 87%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #1879C7;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 80px;
}

.container .btn > span.MuiIconButton-label {
  width: 30px !important;
}

/* Style the button and place it in the middle of the container/image */

.container .btn:hover {
  position: absolute;
  top: 80%;
  left: 87%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #01a9db;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 80px;
}

.btnAdd {
  background-color: #16B1F3;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: Muli;
  font-weight: 600;
}

.slider img {
  flex: 0 0 100%;
  width: 100%;
  object-fit: cover;
}

.btn-modal-image {
  border-radius: 5px;
  background-color: #16B1F3;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.cropper-modal {
  background-color: transparent !important;
}

 html {
   font-size: 16px !important;
   color: white !important;
   background-color: #121212 !important;
 }

 /* label {
   color: #9e9e9e !important;
   font-size: 16px !important;
 }

 input{
  font-size: 16px !important;
 }

 button{
   font-size: 16px !important;
 } */

 /* Global css */

 .container-form {
   background-color: #232323;
   padding: 15px;
   border-radius: 8px;
   min-height: 600px;
 }

 .container-home {
   padding: 15px;
 }

 /* temporal */

 /* .MuiStepLabel-label.MuiStepLabel-active {
   border-bottom: 1px solid #50C1EE;
   padding-bottom: 10px;
   padding-right: 50px;
   padding-left: 50px;
 } */

 .bgtext:after {
   content: "Background text";
   position: absolute;
   top: 0;
   left: 0;
   z-index: -1;
 }

 .comunContainer {
   margin-top: 15px;
   margin-bottom: 15px;
   display: block;
 }

 .comunContainerHide {
   margin-top: 15px;
   margin-bottom: 15px;
   display: none;
 }

 .centerContainer {
   width: 100%;
   text-align: center;
 }

 /* Global css */

 .card .card-action:last-child {
   border-radius: 10px !important;
 }

 .card-blue {
   background-color: #16b1f3;
   border-radius: 10px !important;
   font-size: 15px;
 }

 .card-blue-title {
   padding-left: 10px !important;
   padding-top: 30px !important;
 }

 .card-blue-content {
   padding: 25px 10px 5px 25px !important;
 }

 .card-gray {
   background-color: #2e2e2e !important;
   border-radius: 10px !important;
 }

 .label-big {
   font-size: 40px !important;
 }

 .btn-yellow {
   background-color: #f5d13d !important;
   width: 100%;
   height: 50px;
   font-weight: bold;
   border-radius: 15px;
   border: none;
   cursor: pointer;
   color: white;
   font-size: 16px;
 }

 .icon-shadow {
   border: 4px;
 }

 /* Begin scroll */

 /* width */

 ::-webkit-scrollbar {
   width: 8px;
 }

 /* Track */

 ::-webkit-scrollbar-track {
   background: #f1f1f1;
 }

 ::-webkit-scrollbar-track {
   background: #232323;
 }

 ::-webkit-scrollbar {
   width: 5px;
 }

 /* Handle */

 ::-webkit-scrollbar-thumb {
   background: gray;
   border-radius: 5px;
   height: 10px !important;
 }

 /* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
   background: #555;
 }

 /* End scroll */

 /* Begin Stepper My Account */

 .stepper-title {
   font-size: 16px !important;
   padding-bottom: 10px;
   color: white;
 }

 /* End Stepper  My Account * */

 .title-yellow {
   height: 25px;
   border-radius: 5px;
   background-color: #F5D13D;
   color: #2C2C2C;
   font-size: 16px;
   font-weight: bold;
   letter-spacing: 0;
   text-align: center;
 }

 .custom-collapsible {
   border-radius: 5px !important;
   border: none !important;
 }

 .custom-collapsible-header {
   border-top-left-radius: 5px !important;
   border-top-right-radius: 5px !important;
   background-color: rgba(255, 255, 255, 0.18) !important;
   border: none !important;
   color: #FFFFFF !important;
   font-size: 16px !important;
 }

 .custom-collapsible-body {
   border: none !important;
   border-bottom-left-radius: 5px !important;
   border-bottom-right-radius: 5px !important;
   background-color: rgba(255, 255, 255, 0.18) !important;
 }

 .btn-primary {
   border-radius: 5px;
   background-color: #16B1F3;
   width: 100%;
   height: 50px;
   border: none;
   cursor: pointer;
   color: white;
   font-size: 16px;
 }

 .btn-invoice {
   border-radius: 5px;
   background-color: #16B1F3;
   width: 50%;
   height: 50px;
   border: none;
   cursor: pointer;
   color: white;
   font-size: 16px;
 }

 .container-title {
   width: 100%;
   border-radius: 5px;
   background-color: rgba(18, 18, 18, 0.3);
   color: #2CC63E;
   font-size: 16px;
   text-align: center;
   font-weight: bold;
   padding-top: 10px;
   padding-bottom: 10px;
   margin-top: 10px;
   margin-bottom: 10px;
 }

 /* .card-columns-user {
} */

 /*Estilo switch */

 .switch label .inputCheck[type="checkbox"]:checked+.lever {
   background-color: #9FF781;
 }

 .switch label .inputCheck[type="checkbox"]:checked+.lever:after {
   background-color: #40FF00;
 }

 .switch label .lever {
   display: inline-block;
   position: relative;
   width: 36px;
   height: 14px;
   background-color: rgba(69, 90, 100, 0.4);
   border-radius: 15px;
   margin-right: 10px;
   transition: background 0.3s ease;
   vertical-align: middle;
   margin: 0 16px;
 }

 .switch label .lever:after {
   background-color: #D8D8D8;
   box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
 }

 .inputCheck[type="checkbox"]:checked:not(:disabled)~.lever:active::before, .inputCheck[type="checkbox"]:checked:not(:disabled).tabbed:focus~.lever::before {
   transform: scale(2.4);
   background-color: rgba(206, 147, 216, 0.4);
 }

 /*Estilo Circle de iconos*/

 .circle {
   border-radius: 50%;
   background: #82FA58;
   color: black;
 }

 /*Estilos para MyAccount*/

 .toolip-help {
   color: #2dbf3e;
 }

 .scrollPanel {
   width: 100%;
   border: none;
   overflow: auto;
   position: relative;
   max-height: 1000px;
   border-radius: 5px;
   background-color: transparent !important;
   margin-right: 10px;
 }

 .custom-collapse {
   background-color: rgba(255, 255, 255, 0.18);
 }

 /*CAMBIO FERNANDO RUIZ TOAST STYLE*/

 /* Begin styles Toast */

 .ct-toast {
   background-color: rgba(95, 93, 93, 0.83) !important;
   color: white !important;
   width: 70% !important;
   justify-content: left !important;
 }

 /* End styles Toast */

 .swal2-container {
   z-index: 99999 !important;
 }

 .swal2-popup {
   background-color: #121212 !important;
   color: white !important;
 }

 .swal2-content {
   color: white !important;
 }

 .swal2-title {
   color: white !important;
 }
.createInvoice{
  height: 40px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(255,255,255,0.18);
  color: #FFFFFF;
  font-family: Muli;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding-top: 0.8%;
}
.createInvoice:hover{
    cursor: pointer;
   
  }
  .cardDetailInvoice {
    height: 98px;
    width: 98%;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.11);
    margin-bottom: 12px;
    cursor: pointer;
    padding: 1%;
  }
  .cardDetailInvoiceSelected {
    height: 98px;
    width: 98%;
    border-radius: 4px;
    background-color: #16B1F3;
    margin-bottom: 12px;
    cursor: pointer;
    padding: 1%;
    box-shadow: 0 0 7px 0 #16B1F3;
  }
  .cardDetailInvoiceFullData {
    width: 98%;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.11);
    margin-bottom: 12px;
    padding: 1%;
  }

  .cardDetailInvoice:hover{
    border-color: #16B1F3;
    border-style: ridge;
    /*box-shadow: 0 0 7px 0 #16B1F3;*/
  }
  .cardDetailInvoice:active{
    background-color: rgb(188, 218, 231);
    box-shadow: 0 0 7px 0 #16B1F3;
  }
  .gridNoSpace{
    display: flex;
    padding-left: 5px;
  }
  .nameCostumer{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    max-height: 30px; 
    overflow: hidden;   
  }
  .dataInvoiceGrid{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
  }
  .dataNumberInvoice{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 20px;
  }
  .titleArticleInvoice{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    padding-top: 1.3%;
    max-height: 30px; 
    overflow: hidden;  
  }
  .titleArticleInvoiceItalic{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    font-style: italic;
  }
  .totalInvoiceDetail{
    color: #2CC63E;
    font-family: Muli;
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0;
    text-align: left;
  }
  .totalInvoiceGridPaidout{
    color: #2CC63E;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    text-align: right;
  }
  .totalInvoiceGridPending{
    color: #F5D13D;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    text-align: right;
  }
  .totalInvoiceGridExpired{
    color: #EC005F;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    text-align: right;
  }
  .totalInvoiceGridSelected{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    text-align: right;
  }
  .stateInvoicePaidout{
    color: #2CC63E;
    font-family: Muli;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: right;
  }
  .stateInvoicePending{
    color: #F5D13D;
    font-family: Muli;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: right;
  }
  .stateInvoiceExpired{
    color: #EC005F;
    font-family: Muli;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: right;
  }
  .stateInvoiceSelected{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 23px;
    text-align: right;
  }
  .detailInvoiceNumber{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    text-align: left;
  }
  .detailInvoiceInfo{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 16px;
    font-weight: 500;
    
  }
  .buttonFilter{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    background-color: transparent;
  }
  .lineFinder{
    box-sizing: border-box;
    border-bottom-color: solid #848484;
    color: white;
  }
  .spacingInvoice{
      margin-bottom: 10px;
  }
  .spacingInvoiceMini{
    margin-bottom: 5px;
}
.searchDocuments{
  color: #FFFFFF; 
}
  .lineDivision{
    box-sizing: border-box;
    height: 1px;
    width: 98%;
    border: 1px solid rgba(255,255,255,0.2); 
  }
  .lineDivisionFields{
    box-sizing: border-box;
    height: 1px;
    border: 1px solid rgba(255,255,255,0.2);
  }
  .constumerNameDetail{
    color: #F5D13D;
    font-family: Muli;
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 25px;
    text-align: left;
    margin-top: 3%;
  }
  .greenTitleInvoice{
    color: #2CC63E;
    font-family: Muli;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    border-radius: 5px;
    height: 36px;
    padding-top: 0.6%;
  background-color: rgba(18,18,18,0.3);
  }
  .bottomButtonsInvoice{
    border-radius: 5px;
    background-color: #393939;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    height: 72px;
    text-align: center;  
    padding-top: 2%; 
  }
  .bottomButtonsInvoiceRevision{
    border-radius: 5px;
    background-color: #393939;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    height: 64px;
    text-align: center; 
    padding-top: 0.8%; 
  }
  .bottomButtonsCreateInvoice{
    border-radius: 5px;
    background-color: #393939;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    height: 64px;
    text-align: center;   
    padding-top: 0.8%;
  }
  .tabInfoAdicional{
    border-radius: 5px;
    background-color: #2CC63E;
    height: 37px;
    width: 100%;
    color: #393939;
    font-family: Muli;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
    padding-top: 1%;
  }
  .buttonGroupInovice{
    border-radius: 3.6px;
    background-color: rgba(255,255,255,0.2);
    height: 34px;
    text-align: center;
  }
  .buttonGreenInvoice{
    height: 28.33px;
    border-radius: 4px;
    background-color: #2CC63E;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    color: #FFFFFF;
    font-family: Muli;
    font-size: 18px;
    font-weight: 300;
    width: 48%;
    border: 0px;
    cursor: pointer;
    margin-top: 3px;
  }
  .buttonGrayInvoice{
    height: 28.33px;
    border-radius: 4px;
    background-color: rgba(255,255,255,0.2);
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);
    color: #FFFFFF;
    font-family: Muli;
    font-size: 18px;
    font-weight: 300;
    width: 48%;
    border: 0px;
    cursor: pointer;
    margin-top: 3px;
  }
  .miniLetterInvoice {
    color: rgba(255,255,255,0.5);
    font-family: Muli;
    font-size: 10px;
  }
  .textButtonsInvoice{
    color: #FFFFFF;
    font-family: Muli;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0;
    text-align: center;
  }
  .buttonInvoiceBehavior{
      cursor: pointer;
  }
  .buttonInvoiceConfirm{
    height: 54px;
    width: 98%;
    border-radius: 4px;
    background-color: #16B1F3;
    border: #16B1F3;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
  .buttonInvoiceConfirm:hover{
    box-shadow: 0 0 7px 0 rgb(125, 172, 192);
  }
  .buttonSecondary{
    height: 54px;
    width: 98%;
    border-radius: 4px;
    background-color: #7DD1F8;
    border:#7DD1F8;
    color: #FFFFFF;
    font-family: Muli;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
  }
  .buttonSecondary:hover{
    box-shadow: 0 0 7px 0 rgb(125, 172, 192);
  }
  .modalEmail{
    padding-left: 9px;
    padding-bottom: 20px;
    padding-top: 6px;
  }
  .modalStyleInvoice{
    border-radius: 4px;
    background-color: #2D2D2D;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
    color: white;
    font-family: Muli;
    font-size: 16px;
    letter-spacing: 0;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    position:absolute;
    padding: 1%;

  }
.fieldsReadMini{
  color: rgba(255,255,255,0.5);
  font-family: Muli;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 18px;
}
.fieldsReadContainer{
  padding-top: 7px;
}
.fieldsReadBig{
  color: #FFFFFF;
  font-family: Muli;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 23px;
  padding-top: 0.6%;
}
.paddingLeft{
  padding-left: 5px;
}
.titleAdicitional{
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18,18,18,0.3);
  color: #2CC63E;
  font-family: Muli;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  padding-top: 0.8%;
}
.cardItemInvoice{
  height: 190px;
  width: 99%;
  border-radius: 4px;
  background-color: rgba(255,255,255,0.11);
  fill: Solid;
}
.cardDetailInfoAditional{
  background-color: #5E5E5E;
  border-radius: 5px;
  padding: 2.9%;
}

.circlesCategories{
  height: 19px;
  width: 19px;
  border-radius: 9.5px;
  padding-top: 1%;
}

.cardCostumerInvoice{
  background-color : #3B3B3B;
  border-radius:4px; 
  padding: 1%;  
  padding-left: 3%; 
}
.panelOverflowInvoice{
  overflow-y: auto;
  overflow-x: hidden;
}
.panelOverflowInvoiceCostumer{
  overflow: hidden !important;
  padding-right: 5px;
  height: 100%;
}
.panelVisibleInvoice{
  display: block;
}
.panelInvisibleInvoice{
  display: none;
}
.panelColorInvoice{
  color: #FFFFFF;
}
.iconColorInvoice{
  color: #2CC63E;
}
.aditionalInfoTitle{
  height: 36px;
  width: 100%;
  border-radius: 5px;
  background-color: rgba(18,18,18,0.3);
  color: #2CC63E;
  font-family: Muli;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  padding-top: 0.8%;
}
.totalRevision{
  color: #2CC63E;
  font-family: Muli;
  font-size: 26px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 39px;
  text-align: center;
}
.commentsRevision{
  color: #FFFFFF;
  font-family: Muli;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
}
.dateRevision{
  color: #FFFFFF;
  font-family: Muli;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: right;
  padding-right: 2%;
}
.backgroundRevision{
  background-color:  #4B4B4B;
  border-radius: 5px;
  padding: 1%;
}
.invoiceConfirmation{
  position: absolute;
  width: 100%;
  top: 70px;
  left: 0px;
  background-color: darkgrey;
  z-index: 1000;
  background-color: rgba(18,18,18,0.8);
  
}
.letterTextSearch.MuiInput-underline {
  color: #FFFFFF;
  border-color: #4B4B4B;
}
.letterTextSearch.MuiInput-underline::before{
  border-color: #4B4B4B;
}
.letterTextSearch.MuiInput-underline::hover{
  border-color: #4B4B4B;
}
.letterTextSearch.MuiInput-underline::after{
  border-color: #4B4B4B;
}
.fenomenalWhite{
  color: #EBEBEB;
  font-family: Muli;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 44px;
  text-align: center;
}
.fenomenalWhiteLittle{
  color: #EBEBEB;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder; 
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlueLittle{
  color: #16D5F9;
  font-family: Muli;
  font-size: 16px;
  font-weight: bolder; 
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
}
.fenomenalBlue{
  color: #16D5F9;
  font-family: Muli;
  font-size: 30px;
  letter-spacing: 0;
  line-height: 38px;
  text-align: center;
  font-weight: bolder; 
}
.imageConfirmation{
  height: 280px;
}
.skipMessage{
  color: #16B1F3;
  font-family: Muli;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 36px;
  text-align: right;
  cursor: pointer;
}
.panelCentered{
  text-align: center;
}
.backInvoice{
  color: #FFFFFF;
  font-family: Muli;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  cursor: pointer;
}
.validatorOthers{
  color: red;
  font-size: 14px;
  text-align: center;
  padding-top: 2%;
}
.verticalLineInvoice{
  border-right: 1px solid rgba(255,255,255,0.3);
  height: 90px;
  margin-right: 30%;
}






.containerGrid {
    width: 100%;
    height: 100%;
  }

  /* Make the image responsive */
  .containerGrid img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  /* Style the button and place it in the middle of the container/image */
  .containerGrid .span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    padding: 50px 50px;
    border: none; 
    border-radius: 80px;
    font-weight: bold;
    font-size: 50px;
  }
  
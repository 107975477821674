.MuiList-root {
  background-color: #121212;
}

ul.MuiList-root li span {
  color: #9e9e9e;
}

ul.dropdown-content.select-dropdown li span:hover {
  background-color: #9e9e9e !important;
}


.MuiMenuItem-root:hover {
    background-color: #4A4A4A !important;
}
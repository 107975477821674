.title .MuiTypography-h5 {
    font-size: 20px;
    font-family: "Muli";
    font-weight: bold;
    line-height: 1.334;
    letter-spacing: 0em;
    color: #ffffff;
    text-align: center;
}

.titleCard .MuiTypography-h5 {
    font-size: 20px;
    font-family: "Muli";
    font-weight: bold;
    line-height: 1.334;
    letter-spacing: 0em;
    color: #ffffff;
    margin-left: 20px;
}

  
  /* Style the button and place it in the middle of the container/image */
  .containerImg .span {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    color: white;
    padding: 50px 50px;
    border: none; 
    border-radius: 80px;
    font-family: Muli;
    font-weight: bold;
    font-size: 30px;
  }

.colorBlanco{
  color: white;
}


.imagen {
  border: solid 4px #16B1F3;
  border-radius: 50%;
  width: 150px;
  height: 150px;
}

.input-image {
  display: none !important;
  border-color: #424242 !important;
  border: none !important;
  background-color: transparent !important;
}

.flex-col-scroll {
  flex-grow: 1;
  overflow: auto;
  min-height: 100%;
}

.container {
  position: relative;
  width: 100%;
  /* max-height: 35vh; */
}

/* Imagen con aspecto 4:3 */
.container-img {
  width: 100%;
  background-repeat: no-repeat !important;
  -moz-background-size: cover !important;
  -webkit-background-size: cover !important;
  -o-background-size: cover !important;
  padding-top: 65% !important;
  border-radius: 10px !important;
  background-size: contain !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
}


/* Make the image responsive */

.container img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Style the button and place it in the middle of the container/image */

.container .btn {
  position: absolute;
  top: 80%;
  left: 87%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #1879C7;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 80px;
}

.container .btn > span.MuiIconButton-label {
  width: 30px !important;
}

/* Style the button and place it in the middle of the container/image */

.container .btn:hover {
  position: absolute;
  top: 80%;
  left: 87%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  background-color: #01a9db;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 80px;
}

.btnAdd {
  background-color: #16B1F3;
  color: white;
  padding: 12px 12px;
  border: none;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-family: Muli;
  font-weight: 600;
}

.slider img {
  flex: 0 0 100%;
  width: 100%;
  object-fit: cover;
}

.btn-modal-image {
  border-radius: 5px;
  background-color: #16B1F3;
  width: 100%;
  height: 30px;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin-top: 5px;
  margin-bottom: 15px;
  margin-left: 15px;
}

.cropper-modal {
  background-color: transparent !important;
}

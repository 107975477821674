

.select-wrapper input.select-dropdown {
    color: aliceblue;
}

.MuiAutocomplete-option {
    background-color: #222222;
    color: #FAFAFA;
}

.MuiAutocomplete-option:hover {
    background-color: #4A4A4A !important;
}

.MuiAutocomplete-option:focus {
    background-color: #c00f0f !important;
}

.MuiAutocomplete-noOptions {
    background-color: #222222;
    color: #FAFAFA !important;
}

.MuiAutocomplete-listbox{
    background-color: #222222;
}